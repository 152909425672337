import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import checkmarkIconSrc from 'src/images/at-risk-checkmark-clinic.svg';
import flagIconSrc from 'src/images/at-risk-flag-clinic.svg';
// import bulletSrc from 'src/images/bullet.svg';
import translations from 'src/messages/translations';

import styles from './SuccessPredictorHelpContent.module.scss';

export function SuccessPredictorHelpContent() {
  const intl = useIntl();
  const videoSrc =
    'https://player.vimeo.com/video/711432133?h=0f8f961c23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
  const footerLink = 'https://app.bigtincan.com/';

  return (
    <div className={styles.atRiskHelpContentContainer}>
      <div className={styles.helpContentSection}>
        <div className={styles.helpContentVideoWrapper}>
          <iframe
            className={styles.helpContentVideo}
            allowFullScreen
            src={videoSrc}
            allow="autoplay; fullscreen; picture-in-picture"
            title="Success Predictor Overview"
          />
        </div>
        <h4 className={styles.helpContentTitle}>
          {intl.formatMessage(translations.atRiskModalContent1)}
        </h4>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph1)}
        </p>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph2)}
        </p>
        <ul className={styles.helpContentList}>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph2Item1)}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph2Item2)}
          </li>
        </ul>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent1Paragraph3)}
        </p>
        <ul className={styles.helpContentList}>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item1)}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item2)}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent1Paragraph3Item3)}
          </li>
        </ul>
      </div>
      <div className={styles.helpContentSection}>
        <h4 className={styles.helpContentTitle}>
          {intl.formatMessage(translations.atRiskModalContent2)}
        </h4>
        <p className={styles.helpContentDescription}>
          <img className={styles.riskIcon} src={flagIconSrc} alt="flag" />
          {intl.formatMessage(translations.atRiskModalContent2Paragraph1)}
        </p>
        <ul className={styles.helpContentList}>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph1Item1)}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph1Item2)}
          </li>
        </ul>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent2Paragraph2)}
        </p>
        <ul className={styles.helpContentList}>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item1, {
              b: (chunks: ReactNode[]) => <span className={styles.customBold}>{chunks}</span>,
            })}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item2, {
              b: (chunks: ReactNode[]) => <span className={styles.customBold}>{chunks}</span>,
            })}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph2Item3, {
              b: (chunks: ReactNode[]) => <span className={styles.customBold}>{chunks}</span>,
            })}
          </li>
        </ul>
        <p className={styles.helpContentDescription}>
          <img className={styles.riskIcon} src={checkmarkIconSrc} alt="checkmark" />
          {intl.formatMessage(translations.atRiskModalContent2Paragraph3)}
        </p>
        <ul className={styles.helpContentList}>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph3Item1)}
          </li>
          <li className={styles.helpContentListItem}>
            {intl.formatMessage(translations.atRiskModalContent2Paragraph3Item2)}
          </li>
        </ul>
      </div>
      <div className={styles.helpContentSection}>
        <h4 className={styles.helpContentTitle}>
          {intl.formatMessage(translations.atRiskModalContent3)}
        </h4>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent3Paragraph1)}
        </p>
        <p className={styles.helpContentDescription}>
          {intl.formatMessage(translations.atRiskModalContent3Paragraph2)}
        </p>
      </div>
      <div className={styles.helpContentSection}>
        <p className={styles.helpContentFooter}>
          {intl.formatMessage(translations.atRiskModalFooterLink, {
            a: (chunks: ReactNode[]) => (
              <a href={footerLink} target="_blank" rel="noreferrer">
                {chunks}
              </a>
            ),
          })}
        </p>
      </div>
    </div>
  );
}
