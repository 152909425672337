import { ChangeEvent } from 'react';

import { AttachFileIcon } from 'src/icons/AtachFileIcon';
import { UPLOAD_ATTACHMENT } from 'src/utils/constants';

import styles from './ChatAttachButton.module.scss';

const selector = {
  hiddenFileInput: 'chat-attach-hidden-file-input',
  buttonWrapper: 'chat-attach-button',
};

type Props = {
  onClick: Function;
};

const validFileExts = '.jpeg,.jpg,.png,.gif,.pdf,.m4a,.mp3';

export function ChatAttachButton({ onClick }: Props) {
  return (
    <>
      <input
        className={styles.hiddenFileInput}
        id={UPLOAD_ATTACHMENT}
        type="file"
        onChange={selectFile}
        accept={validFileExts}
        data-cy={selector.hiddenFileInput}
      />
      <label
        className={styles.buttonWrapper}
        htmlFor={UPLOAD_ATTACHMENT}
        data-cy={selector.buttonWrapper}
      >
        <AttachFileIcon />
      </label>
    </>
  );

  function selectFile(event: ChangeEvent<HTMLInputElement>) {
    event.persist();
    const { files } = event.currentTarget || {};

    if (files) {
      onClick(files[0]);
    }
  }
}
