import { getInitials } from '@allurion/domain';
import { classNames } from '@allurion/ui';
import { isToday, toLocalizedShortDateTime, toLocalizedTime } from '@allurion/utils';
import { Media } from '@twilio/conversations';

import { ParseHyperlinks } from 'src/components/ParseHyperlinks';
import { useLocale } from 'src/hooks/useLocale';

import { ChatAttachment } from './ChatAttachment';
import { ChatReadIndicator } from './ChatReadIndicator';

import styles from './ChatMessage.module.scss';

const selector = {
  container: 'chat-message-container',
  info: 'chat-message-info',
  user: 'chat-message-user',
  date: 'chat-message-date',
};

type Props = {
  data: {
    name: string;
    message: string;
    date: Date | null;
    id: string;
    isProvider: boolean;
    isMe: boolean;
    attachment?: Media;
    index: number;
  };
  lastReadIndex?: number;
};

export function ChatMessage({ data, lastReadIndex }: Props) {
  const { locale } = useLocale();
  const { name, date, message, isProvider, isMe, attachment, index } = data || {};
  const initials = getInitials(name);

  const formattedDate = date
    ? isToday(date)
      ? toLocalizedTime(date, locale)
      : toLocalizedShortDateTime(date, locale)
    : '-';

  return (
    <>
      <div
        className={classNames(styles.container, isMe && styles.isMe)}
        data-cy={selector.container}
      >
        <div className={classNames(styles.avatar, isProvider && styles.isProvider)}>{initials}</div>
        <div className={styles.content}>
          <div className={styles.title} data-cy={selector.info}>
            <span className={styles.user} data-cy={selector.user}>
              {name}
            </span>
            <span className={styles.date} data-cy={selector.date}>
              {formattedDate}
            </span>
          </div>
          <div className={styles.message}>
            <ParseHyperlinks message={message} />
            {attachment && <ChatAttachment file={attachment} />}
          </div>
        </div>
      </div>

      <ChatReadIndicator isProvider={isProvider} index={index} lastReadIndex={lastReadIndex} />
    </>
  );
}
