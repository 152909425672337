import { useEffect, useState } from 'react';
import * as React from 'react';

import { readURL, getReadableFileSize, imageTypes } from 'src/helpers/file';
import { CancelAltIcon } from 'src/icons/CancelAltIcon';
import audioIconSrc from 'src/images/audio-icon.png';
import pdfIconSrc from 'src/images/pdf-icon.png';
import colors from 'src/styles/theme/colors';

import styles from './FilePreview.module.scss';

type Props = {
  file: File | null;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
};

export function FilePreview({ file, onDelete }: Props) {
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    const preview = async () => {
      const url: any = await readURL(file!);

      setImgUrl(url);

      return url;
    };

    if (file) {
      preview();
    }
  }, [file]);

  const { type, size, name } = file || {};
  const humanReadableSize = getReadableFileSize(size!);

  const isImage = imageTypes.includes(type!);
  const isPdf = type === 'application/pdf';
  const isAudio = type === 'audio/x-m4a' || type === 'audio/mpeg';
  const isSupportedType = isImage || isPdf || isAudio;

  if (!isSupportedType) {
    return null;
  }

  return (
    <div className={styles.previewContainer}>
      <button type="button" className={styles.closeBtn} onClick={onDelete}>
        <CancelAltIcon color={colors.Primary} />
      </button>

      {isImage ? (
        <img className={styles.thumbnail} src={imgUrl} alt="images" />
      ) : isPdf ? (
        <img className={styles.pdfIcon} src={pdfIconSrc} alt="pdf" />
      ) : (
        <img className={styles.audioIcon} src={audioIconSrc} alt="audio" />
      )}
      <p>{humanReadableSize}</p>
      <p>{name}</p>
    </div>
  );
}
