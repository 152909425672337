import { createBridge, ContextMenu, useContextMenu } from 'react-context-menu-hooks';

import 'react-context-menu-hooks/src/ContextMenu.css';
import styles from './MouseContextMenu.module.scss';

export interface ContextMenuTriggerData {
  select: (action: any) => typeof action;
}

export const menuBridge = createBridge<ContextMenuTriggerData>({
  select: (action: any) => action,
});

type Props = {
  menuOptions: {
    label: string;
    value: string;
    disabled?: boolean;
  }[];
};

export function MouseContextMenu({ menuOptions }: Props) {
  const { select } = useContextMenu(menuBridge);

  const handleOnSelect = (action: any) => {
    select(action);
  };

  // NOTE: <span> wrapper helps to dismiss the custom context menu :)
  return (
    <span>
      <ContextMenu className={styles.contextMenu} bridge={menuBridge} onSelect={handleOnSelect}>
        {menuOptions?.map(({ label, value, disabled = false }) => (
          <ContextMenu.Option key={`ctx-menu_${value}`} disabled={disabled} select={value}>
            {label}
          </ContextMenu.Option>
        ))}
      </ContextMenu>
    </span>
  );
}
