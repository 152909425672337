import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import styles from './ScrollingMenu.module.scss';

interface Item {
  label: string;
  value?: any;
}

type Props = {
  data: Item[];
  renderCard: (item: Item, index: number) => any;
  onSelect?: (item: any) => void;
};

export function ScrollingMenu({ data, renderCard, onSelect }: Props) {
  return (
    <div className={styles.container}>
      <ScrollMenu>
        {data.map((item: any, index: number) => (
          <div key={index} className="menu-item" onClick={onSelect}>
            {renderCard(item, index)}
          </div>
        ))}
      </ScrollMenu>
    </div>
  );
}
