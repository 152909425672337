import { isRtlLocale } from '@allurion/utils';
import { useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { ParsedMessageNotification } from 'src/domain/Conversations';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useLocale } from 'src/hooks/useLocale';
import { refreshMessageNotifications } from 'src/hooks/useMessageNotifications';
import { fetchPatientData } from 'src/hooks/usePatientProfile';
import { useUserClinics } from 'src/hooks/useUserClinics';
import { CloseIcon } from 'src/icons/CloseIcon';
import { MailOutlineIcon } from 'src/icons/MailOutlineIcon';
import { Logger } from 'src/services/Logger';
import { useTwilioIncommingMessages } from 'src/twilio/useTwilioIncommingMessages';
import { KEY_CHAT } from 'src/utils/constants';

import styles from './MessageNotification.module.scss';

const disabledPaths = ['/create-password'];

const timeout = 20000;
const messageNotificationId = 'message-notification';
const notificationsLimit = 7;

function Content({ title, body }: IMessageNotification) {
  return (
    <div className={styles.notification}>
      <div className={styles.icon}>
        <MailOutlineIcon size="24" fill="#00363F" />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.body}>{body}</div>
      </div>
    </div>
  );
}

const showToast = ({ title, body, onClick, toastId, onClose }: IShowToast) =>
  toast(<Content title={title} body={body} />, {
    containerId: messageNotificationId,
    onClick,
    toastId,
    onClose,
  });

export const MessageNotification = memo(function MessageNotification() {
  const { locale } = useLocale();
  const intl = useIntl();
  const { user } = useCurrentUser();
  const { currentProviderIdentity } = useCurrentProvider();
  const { clinics } = useUserClinics();
  const location = useLocation();
  const { toPatientPage } = useAppNavigate();

  const isDisabled = disabledPaths.includes(location.pathname);

  const identity = currentProviderIdentity;
  const { incomingMessage, clearIncomingMessage } = useTwilioIncommingMessages(identity);

  //FIXME: replace with callback in useTwilioIncommingMessages
  useEffect(() => {
    const showNotification = async (payload: ParsedMessageNotification) => {
      const { author, title, body, patientId, isPatientMessage } = payload || {};

      const shouldNotDisplay = identity === author || location.hash.includes(KEY_CHAT);
      let clinicCheck = true;

      // patient data cannot be fetched without a patientId
      if (!patientId) {
        Logger.captureMessage('User received a notification without a patientId');

        return;
      }

      const patientData = await fetchPatientData(patientId!);

      const patientClinicId = patientData?.hospitalid.toString();

      const userHasAccessToClinic =
        patientClinicId && clinics.find((clinic) => clinic.ID === patientClinicId);

      if (!userHasAccessToClinic) {
        // eslint-disable-next-line no-console
        Logger.captureException(
          new Error(
            `User should not see patient message notification from clinic ${patientClinicId}`
          )
        );
        clinicCheck = false;
      }

      if (!shouldNotDisplay && clinicCheck && isPatientMessage) {
        refreshMessageNotifications();

        showToast({
          title: title!,
          // body is null when attachment is sent
          body:
            body ??
            intl.formatMessage({
              id: 'message-notification.sent-attachment',
              defaultMessage: 'Sent an attachment',
            }),
          onClick: () => {
            if (!patientClinicId) {
              Logger.captureMessage(
                'Cannot navigate to patient page from a message notification without a clinicId'
              );

              return;
            }

            if (patientId) {
              toPatientPage(patientClinicId, patientId, KEY_CHAT);
            }
          },
          toastId: author,
          onClose: clearIncomingMessage,
        });
      }
    };

    // There is a change that `incommingMessage` could be truthy, so we use `user.sessionToken` to validate if the user is login
    // there is a redirect bug happening
    // making a api call to getPatient data, but user has no session, and we get 401, and we get a userSession timeout
    if (!isDisabled && incomingMessage && user.sessionToken && identity) {
      showNotification(incomingMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMessage, user.sessionToken, identity]);

  // eslint-disable-next-line react/function-component-definition
  const CloseButton = ({
    closeToast,
  }: {
    closeToast: (e: React.MouseEvent<HTMLElement>) => void;
  }) => (
    <button
      className="close-button"
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        closeToast(e);
      }}
    >
      <CloseIcon size="20" color="#77989E" />
    </button>
  );

  if (isDisabled) {
    return null;
  }

  return (
    <ToastContainer
      className={styles.toastContainer}
      containerId={messageNotificationId}
      autoClose={timeout}
      rtl={isRtlLocale(locale)}
      limit={notificationsLimit}
      closeButton={CloseButton}
      hideProgressBar
      enableMultiContainer
      closeOnClick
    />
  );
});

interface IMessageNotification {
  title: string;
  body?: string;
}

interface IShowToast extends IMessageNotification {
  // onClick?: (event: MouseEvent<Element, MouseEvent>) => void;
  // onClose?: (event: MouseEvent<Element, MouseEvent>) => void;
  onClick?: any;
  onClose?: any;
  toastId?: string;
}
