import uniqueId from 'lodash.uniqueid';

import styles from './ParseHyperlinks.module.scss';

type Props = {
  message: string;
  style?: object;
};

const urlRegex = /((http|https):\/\/[^\s]+)/g;
const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/gi;

export function ParseHyperlinks({ message, style = {} }: Props) {
  if (!message) {
    return null;
  }

  const content = message.split('\n').map((line) => {
    const words = line.split(' ');
    const parsedWords = words.map((word) => {
      if (urlRegex.test(word)) {
        return `<a href="${word}" target="_blank">${word}</a>`;
      } else if (emailRegex.test(word)) {
        return `<a href="mailto:${word}" target="_blank" rel="noreferrer">${word}</a>`;
      } else if (/\.(com|org|net)(\..{2})?\/?/.test(word)) {
        return `<a href="//${word}" target="_blank">${word}</a>`;
      }

      return word;
    });
    const parsedText = parsedWords.join(' ');

    return (
      <p
        className={styles.container}
        key={uniqueId()}
        dangerouslySetInnerHTML={{ __html: parsedText }}
        style={{ ...style }}
      />
    );
  });

  return <>{content}</>;
}
