import { classNames } from '@allurion/ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { LinearProgress } from 'src/ui/LinearProgress';

import messages from './chat.messages';

import styles from './ChatError.module.scss';

const selector = {
  messageTitle: 'chat-error-title',
  messageContainer: 'chat-error-container',
};

type Props = {
  uniqueName: string;
};

export function ChatError({ uniqueName }: Props) {
  const intl = useIntl();
  const [retryLabel, setRetryLabel] = useState(true);

  useEffect(() => {
    const time = 5000;
    const timeout = setTimeout(() => {
      if (!uniqueName) {
        setRetryLabel(false);
      }
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [uniqueName, setRetryLabel]);

  const label = intl.formatMessage(retryLabel ? messages.errorRetry : messages.errorNotInstalled);
  const isVisible = !uniqueName || retryLabel;

  return (
    <div className={styles.errorMessageContainer} data-cy={selector.messageContainer}>
      <p
        className={classNames(styles.messageTitle, isVisible && styles.isVisible)}
        data-cy={selector.messageTitle}
      >
        {label}
      </p>
      {uniqueName && retryLabel && <LinearProgress />}
    </div>
  );
}
