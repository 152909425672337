import { isPatientGroupingEnabled } from '@allurion/domain';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { ClinicStats } from 'src/domain/Clinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useClinicStats } from 'src/hooks/useClinicStats';
import { useCurrentProvider } from 'src/hooks/useCurrentProvider';

import { StatsScrollingList } from './StatsScrollingList';

import styles from './ClinicStatsCards.module.scss';

type Props = {
  clinicId: string;
  isLoadingClinicStats: boolean;
};

export default function ClinicStatCards({ clinicId, isLoadingClinicStats }: Props) {
  const { settings } = useClinicSettings(clinicId);
  const { isAdmin } = useCurrentProvider();
  const { clinicStats, providerStats } = useClinicStats(clinicId);

  const showProviderStats = !isAdmin && isPatientGroupingEnabled(settings);
  const data = showProviderStats ? providerStats : clinicStats;
  const latest = useMemo(() => (data ? data[data.length - 1] : ({} as ClinicStats)), [data]);

  const clinicAggregateData = latest
    ? [
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="total-patients" defaultMessage="Total Patients" />
            </span>
          ),
          value: latest.total_patients,
        },
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="active-patients" defaultMessage="Active Patients" />
            </span>
          ),
          value: latest.active_patients,
        },
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="4-month-TBWL" defaultMessage="Avg. 4-Month TBWL" />
            </span>
          ),
          // eslint-disable-next-line max-len, no-nested-ternary
          value: latest.avg_4mo_tbwl ? `${latest.avg_4mo_tbwl.toFixed(1)}%` : '0.0%',
        },
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="daily-weigh-in" defaultMessage="Daily Weigh-In Rate" />
            </span>
          ),
          value: latest.daily_weigh_rate_clinic
            ? `${(100 * latest.daily_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="weekly-weigh-in" defaultMessage="Weekly Weigh-In Rate" />
            </span>
          ),
          value: latest.weekly_weigh_rate_clinic
            ? `${(100 * latest.weekly_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
        {
          label: (
            <span className={styles.messageWrapper}>
              <FormattedMessage id="monthly-weigh-in" defaultMessage="Monthly Weigh-In Rate" />
            </span>
          ),
          value: latest.monthly_weigh_rate_clinic
            ? `${(100 * latest.monthly_weigh_rate_clinic).toFixed(1)}%`
            : '0.0%',
        },
      ]
    : [];

  return (
    <div className={styles.container}>
      {isLoadingClinicStats && !latest ? (
        <InlineLoader />
      ) : (
        <>
          <h2 className={styles.title}>
            <FormattedMessage
              id="clinic-page.analytics-header"
              defaultMessage="Your patients trends"
            />
          </h2>

          <StatsScrollingList data={clinicAggregateData} />
        </>
      )}
    </div>
  );
}
