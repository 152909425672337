import { isPatientGroupingEnabled } from '@allurion/domain';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { InlineLoader } from 'src/components/ui/InlineLoader';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicHistoricalStats } from 'src/hooks/useClinicHistoricalStats';
import { useClinicSettings } from 'src/hooks/useClinicSettings';
import { useClinicStats } from 'src/hooks/useClinicStats';

import { ActivePatientsGraph } from './ActivePatientsChart';
import { WeightLossGraph } from './WeightLossChart';

import styles from './ClinicTrends.module.scss';

type Props = {
  clinicId: string;
  isLoadingClinicStats: boolean;
};

export function ClinicTrends({ clinicId, isLoadingClinicStats }: Props) {
  const { clinicStats: clinicData } = useClinicStats(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const { historicalStats, isLoading } = useClinicHistoricalStats(clinicId);
  const { clinic } = useClinic(clinicId);
  const clinicName = clinic?.post_title;
  const latest = useMemo(() => (clinicData ? clinicData[clinicData.length - 1] : {}), [clinicData]);
  const intl = useIntl();
  const data = getData(latest);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {isPatientGroupingEnabled(settings)
          ? intl.formatMessage(
              {
                id: 'clinic-trends-for-clinic',
                defaultMessage: 'Clinic Trends for {clinicName}',
              },
              { clinicName }
            )
          : intl.formatMessage({
              id: 'clinic-trends',
              defaultMessage: 'Clinic Trends',
            })}
      </h2>
      {isLoadingClinicStats && data.length === 0 ? (
        <InlineLoader />
      ) : (
        <WeightLossGraph data={data} />
      )}
      {isLoading && historicalStats.length === 0 ? (
        <InlineLoader />
      ) : (
        <ActivePatientsGraph data={historicalStats} clinicId={clinicId} />
      )}
    </div>
  );
}

function getData(latest: any) {
  const data = [];

  for (let month = 1; month <= 12; month += 1) {
    if (latest?.[`median_tbwl_mo${month}`]) {
      data.push({
        '95th Percentile': latest[`ninety_fifth_perc_tbwl_mo${month}`]
          ? latest[`ninety_fifth_perc_tbwl_mo${month}`].toFixed(1)
          : '',
        '75th Percentile': latest[`seventy_fifth_perc_tbwl_mo${month}`]
          ? latest[`seventy_fifth_perc_tbwl_mo${month}`].toFixed(1)
          : '',
        Median: latest[`median_tbwl_mo${month}`] ? latest[`median_tbwl_mo${month}`].toFixed(1) : '',
        '25th Percentile': latest[`twenty_fifth_perc_tbwl_mo${month}`]
          ? latest[`twenty_fifth_perc_tbwl_mo${month}`].toFixed(1)
          : '',
        '5th Percentile': latest[`fifth_perc_tbwl_mo${month}`]
          ? latest[`fifth_perc_tbwl_mo${month}`].toFixed(1)
          : '',
        'Months Into Program': '',
      });
    }
  }

  return data;
}
