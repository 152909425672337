import { classNames } from '@allurion/ui';
import { MouseEvent } from 'react';

import { SendIcon } from 'src/icons/SendIcon';
import theme from 'src/styles/theme';
import { SEND_BUTTON } from 'src/utils/constants';

import styles from './ChatSendButton.module.scss';

const selector = {
  container: 'chat-send-button',
};

type Props = {
  inputValue: string;
  onClick: () => void;
  file: File | null;
};

export function ChatSendButton({ inputValue, onClick, file }: Props) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onClick();
  };

  const hide = !file && !inputValue;

  return (
    <button
      type="button"
      data-cy={selector.container}
      id={SEND_BUTTON}
      onClick={handleClick}
      className={classNames(styles.sendButton, hide && styles.hide)}
    >
      <SendIcon color={theme.colors.Primary} />
    </button>
  );
}
