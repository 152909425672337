import { useIntl } from 'react-intl';

import checkIconSrc from 'src/images/check-icon.svg';

import messages from './chat.messages';

import styles from './ChatReadIndicator.module.scss';

type Props = {
  isProvider: boolean;
  index: number;
  lastReadIndex?: number;
};

const selector = {
  label: 'chat-read-indicator-label',
};

export function ChatReadIndicator({ isProvider, index, lastReadIndex }: Props) {
  const intl = useIntl();
  const lastReadLabel = intl.formatMessage(messages.messageRead);
  const isVisible = isProvider && index === lastReadIndex;

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.unreadMessageLabel}>
      <img className={styles.readCheck} src={checkIconSrc} alt="check" />
      <span className={styles.readLabel} data-cy={selector.label}>
        {lastReadLabel}
      </span>
    </div>
  );
}
