import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import 'chartjs-adapter-date-fns';
import 'chartjs-plugin-style';

import { useDevice } from 'src/hooks/useDevice';
import globalMessages from 'src/messages/global.messages';
import colors from 'src/styles/theme/colors';

import tooltips from './active-patients-chart-tooltips';

import styles from './ActivePatientsChart.module.scss';

Chart.unregister(ChartDataLabels);
Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";

const datasets = (data: any, gradientFill: any) => [
  {
    label: 'Data',
    data,
    borderColor: colors.GraphBlue,
    // hoverBackgroundColor: colors.DarkBlue,
    pointRadius: 6,
    pointHoverRadius: 6,
    pointBorderColor: 'transparent',
    pointHoverBorderColor: colors.DarkBlue,
    pointBackgroundColor: 'transparent',
    pointHoverBackgroundColor: colors.DarkBlue,

    fill: true,
    backgroundColor: gradientFill,
  },
];

type Props = {
  id: string;
  data: any;
};

export function ActivePatientsLineChart({ id, data }: Props) {
  const intl = useIntl();
  const { isTablet } = useDevice();

  const canvasRef = useRef<any>(null);
  const chartRef = useRef<any>(null);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    // var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    // gradientStroke.addColorStop(0, '#80b6f4');
    // gradientStroke.addColorStop(1, '#f49080');

    const gradientFill = ctx.createLinearGradient(0, isTablet ? 200 : 300, 0, 0);

    gradientFill.addColorStop(0, 'rgba(196, 196, 196, 0)');
    gradientFill.addColorStop(1, 'rgba(133, 144, 189, 0.65)');

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        // Bring in data
        // labels: [
        //   intl.formatMessage({ id: globalMessages.Jan.id }),
        //   intl.formatMessage({ id: globalMessages.Feb.id }),
        //   intl.formatMessage({ id: globalMessages.Mar.id }),
        //   intl.formatMessage({ id: globalMessages.Apr.id }),
        //   intl.formatMessage({ id: globalMessages.May.id }),
        //   intl.formatMessage({ id: globalMessages.Jun.id }),
        //   intl.formatMessage({ id: globalMessages.Jul.id }),
        //   intl.formatMessage({ id: globalMessages.Aug.id }),
        //   intl.formatMessage({ id: globalMessages.Sep.id }),
        //   intl.formatMessage({ id: globalMessages.Oct.id }),
        //   intl.formatMessage({ id: globalMessages.Nov.id }),
        //   intl.formatMessage({ id: globalMessages.Dec.id }),
        // ],
        datasets: datasets(data, gradientFill),
      },
      options: {
        responsive: true,
        maintainAspectRatio: isTablet,
        layout: {
          padding: {
            left: isTablet ? 18 : 55,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },
        hover: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            type: 'timeseries',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM dd',
              },
            },
            ticks: {
              display: true,
              source: 'data',
              font: {
                size: isTablet ? 6 : 13,
              },
              callback(value: any) {
                // @ts-ignore
                // eslint-disable-next-line no-underscore-dangle
                const dateTime: string = this._adapter.format(value, 'MMM dd');
                const [month, day] = String(dateTime).split(' ');
                const monthMessage = (globalMessages as any)[month];
                const msg = monthMessage ? intl.formatMessage(monthMessage) : monthMessage;

                return day ? `${msg} ${day}` : `${msg}`;
              },
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            ticks: {
              display: true,
              font: {
                size: isTablet ? 6 : 13,
              },
              padding: isTablet ? 5 : 20,
              callback: (value: string | number, index: number) => {
                if (index % 2 === 0) {
                  return value;
                }

                return null;
              },
            },
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: tooltips,
          legend: {
            display: false,
          },
        },
      },
    });
  }, [isTablet, data, intl]);

  return (
    <div className={styles.container}>
      <canvas id={id} ref={canvasRef} />
    </div>
  );
}
