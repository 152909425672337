import Chart from 'chart.js/auto';
import { useRef, useEffect } from 'react';
import 'chartjs-plugin-style';

import tooltips from './activity-tooltips';

import styles from './ActivityBarChart.module.scss';

Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";

const datasets = (color: string, data: { x: string; y: number }[]) => [
  {
    data,
    label: '',
    backgroundColor: color,
    barPercentage: 0.5,
    borderRadius: 4,
  },
];

type Props = {
  id: string;
  color: string;
  data: { x: string; y: number; date: Date }[];
  stepSize: number;
  ytickFormatter: (value: any, index: number, values: any[]) => any;
  tooltipLabelFormatter: (tooltipItem: any, data: any) => any;
  custom?: (tooltip: any) => any;
};

export function ActivityBarChart({
  id,
  color,
  data,
  stepSize,
  ytickFormatter,
  tooltipLabelFormatter,
  custom,
}: Props) {
  const canvasRef = useRef<any>(null);
  const chartRef = useRef<any>(null);
  const labels = [...data].sort((a, b) => a.date.getTime() - b.date.getTime()).map((d) => d.x);

  useEffect(() => {
    //
    // re-renders on width change to get correct styles
    //
    const ctx = canvasRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: datasets(color, data),
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        // @ts-ignore
        cornerRadius: 1,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 40,
            bottom: 0,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
            border: {
              display: true,
            },
          },
          y: {
            title: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              display: true,
              padding: 5,
              font: {
                size: 10,
              },
              stepSize,
              callback: ytickFormatter,
            },
            grid: {
              display: true,
              color: '#CCD7D9',
              tickLength: 0,
            },
            border: {
              display: false,
              dash: [2, 3],
            },
          },
        },
        plugins: {
          tooltip: tooltips(tooltipLabelFormatter, custom),
          legend: {
            display: false,
          },
        },
      },
    });
  }, [color, custom, data, labels, stepSize, tooltipLabelFormatter, ytickFormatter]);

  return (
    <div className={styles.container}>
      <canvas id={id} ref={canvasRef} />
    </div>
  );
}
