import { isPatientGroupingEnabled } from '@allurion/domain';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ActivePatientsLineChart } from 'src/charts/ActivePatientsLineAreaChart/ActivePatientsChart';
import { useClinic } from 'src/hooks/useClinic';
import { useClinicSettings } from 'src/hooks/useClinicSettings';

import styles from './ActivePatientsChart.module.scss';

type Props = {
  data: null | any[];
  clinicId: string;
};

export function ActivePatientsGraph({ data, clinicId }: Props) {
  const formattedData = useMemo(
    () =>
      data?.map((dataItem: any) => ({
        x: new Date(dataItem.calc_date).getTime(),
        y: dataItem.active_patients,
      })),
    [data]
  );

  const intl = useIntl();
  const { clinic } = useClinic(clinicId);
  const { settings } = useClinicSettings(clinicId);
  const clinicName = clinic?.post_title;

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {isPatientGroupingEnabled(settings)
          ? intl.formatMessage(
              {
                id: 'active-patients-for-clinic',
                defaultMessage: 'Active Patients for {clinicName}',
              },
              {
                clinicName,
              }
            )
          : intl.formatMessage({
              id: 'active-patients',
              defaultMessage: 'Active Patients',
            })}
        {}
      </h2>

      <div
        style={{
          width: '95%',
          height: '90%',
        }}
      >
        {formattedData && (
          <ActivePatientsLineChart id="active-patients-graph" data={formattedData} />
        )}
      </div>
    </div>
  );
}
