import { InfoIcon, CheckCircleIcon } from '@allurion/ui';
import { toast, ToastOptions } from 'react-toastify';

import { GENERIC_TOAST_ID } from 'src/utils/constants';

import styles from './toasts.module.scss';

function ToastLayout({ icon, msg }: any) {
  return (
    <div className={styles.toastWrapper} data-sentry-unmask>
      <div className={styles.iconWrapper}>{icon}</div>
      <span className={styles.toastMessage}>{msg}</span>
    </div>
  );
}

const renderSuccess = (msg: string | any) => <ToastLayout msg={msg} icon={<CheckCircleIcon />} />;
const renderError = (msg: string | any) => <ToastLayout msg={msg} icon={<InfoIcon />} />;
const renderInfo = (msg: string | any) => <ToastLayout msg={msg} icon={<InfoIcon />} />;
const renderWarning = (msg: string | any) => <ToastLayout msg={msg} icon={<InfoIcon />} />;

export const toastSuccess = (msg: string | any, opts: Partial<ToastOptions> = {}) => {
  toast(renderSuccess(msg), {
    type: toast.TYPE.SUCCESS,
    ...opts,
    className: 'toast-container',
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);
};

export const toastInfo = (msg: string | any, opts: Partial<ToastOptions> = {}) =>
  toast(renderInfo(msg), {
    type: toast.TYPE.INFO,
    ...opts,
    className: 'toast-container',
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastError = (msg: string | any, opts: Partial<ToastOptions> = {}) => {
  toast(renderError(msg), {
    type: toast.TYPE.ERROR,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);
};

export const toastWarning = (msg: string | any, opts: Partial<ToastOptions> = {}) =>
  toast(renderWarning(msg), {
    type: toast.TYPE.WARNING,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateSuccess = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderSuccess(msg),
    type: toast.TYPE.SUCCESS,
    className: 'toast-container',
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateInfo = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderInfo(msg),
    type: toast.TYPE.INFO,
    className: 'toast-container',
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateError = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderError(msg),
    type: toast.TYPE.ERROR,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

export const toastUpdateWarning = (toastId: string, msg: string | any, opts = {}) =>
  toast.update(toastId, {
    render: renderWarning(msg),
    type: toast.TYPE.WARNING,
    ...opts,
    containerId: GENERIC_TOAST_ID,
    icon: false,
  } as ToastOptions);

/**
 * Needs work --> not the best animation lol
 *
 * @param {*} toastId
 */
export const progressControl = (toastId: string) => {
  let progress = 1;

  setInterval(() => {
    if (progress <= 0) {
      progress = 1;
    }
    toast.update(toastId, {
      progress: (progress -= 0.01),
      containerId: GENERIC_TOAST_ID,
    });
  }, 30);
};
