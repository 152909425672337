import { useIntl } from 'react-intl';

import messages from './chat.messages';

import styles from './ChatEmpty.module.scss';

const selector = {
  messageTitle: 'chat-empty-title',
  messageSubtitle: 'chat-empty-subtitle',
  messageContainer: 'chat-empty-container',
};

export function ChatEmpty({ name = '' }: { name: string }) {
  const intl = useIntl();

  return (
    <div className={styles.emptyMessageContainer} data-cy={selector.messageContainer}>
      <p className={styles.messageTitle} data-cy={selector.messageTitle}>
        {intl.formatMessage(messages.emptyTitle)}
      </p>

      <p className={styles.messageSubtitle} data-cy={selector.messageSubtitle}>
        {`${intl.formatMessage(messages.emptySubtitle)} ${name}`}
      </p>
    </div>
  );
}
