import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import messages from 'src/charts/WeightLossByPercentageChart//weight-loss-by-percentage-chart.messages';
import { WeightLossByPercentageChart } from 'src/charts/WeightLossByPercentageChart/WeightLossByPercentageChart';
import colors from 'src/styles/theme/colors';

import styles from './WeightLossChart.module.scss';

type Props = {
  data: null | any[];
};

export function WeightLossGraph({ data }: Props) {
  const intl = useIntl();

  const formattedData = useMemo(
    () =>
      data?.reduce((acc: any, item: any) => {
        acc['5th Percentile'] = [...(acc['5th Percentile'] || []), Number(item['5th Percentile'])];
        acc['25th Percentile'] = [
          ...(acc['25th Percentile'] || []),
          Number(item['25th Percentile']),
        ];
        acc.Median = [...(acc.Median || []), Number(item.Median)];
        acc['75th Percentile'] = [
          ...(acc['75th Percentile'] || []),
          Number(item['75th Percentile']),
        ];
        acc['95th Percentile'] = [
          ...(acc['95th Percentile'] || []),
          Number(item['95th Percentile']),
        ];

        return acc;
      }, {}),
    [data]
  );

  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <h2 className={styles.title}>
          <FormattedMessage
            id="weight-loss-distribution"
            defaultMessage="Weight Loss Distribution by Percentile"
          />
        </h2>
        <div className={styles.legendContainer}>
          <div className={styles.legendWrapper}>
            <div className={styles.legendItem}>
              <div className={styles.circle} style={{ backgroundColor: colors.DarkBlue }} />
              <div className={styles.textSpan}>{intl.formatMessage(messages.Median)} TBWL</div>
            </div>
            <div className={styles.rowItems}>
              <div className={styles.legendItem}>
                <div className={styles.circle} style={{ backgroundColor: colors.MintGreen }} />
                <div className={styles.textSpan}>
                  95th {intl.formatMessage(messages.Percentile)} TBWL
                </div>
              </div>
              <div className={styles.legendItem}>
                <div className={styles.circle} style={{ backgroundColor: colors.GraphBlue }} />
                <div className={styles.textSpan}>
                  75th {intl.formatMessage(messages.Percentile)} TBWL
                </div>
              </div>
            </div>
            <div className={styles.rowItems}>
              <div className={styles.legendItem}>
                <div className={styles.circle} style={{ backgroundColor: colors.SeaGreen }} />
                <div className={styles.textSpan}>
                  25th {intl.formatMessage(messages.Percentile)} TBWL
                </div>
              </div>
              <div className={styles.legendItem}>
                <div className={styles.circle} style={{ backgroundColor: colors.DarkGrey }} />
                <div className={styles.textSpan}>
                  5th {intl.formatMessage(messages.Percentile)} TBWL
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '95%',
          height: '90%',
        }}
      >
        {formattedData && (
          <WeightLossByPercentageChart id="weight-loss-distribution-graph" data={formattedData} />
        )}
      </div>
    </div>
  );
}
