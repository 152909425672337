import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './FeatureFlags.module.scss';

const FEATURE_FLAG_PREFIX = 'al-';
const FEATURE_FLAGS_STORAGE_KEY = 'al-feature-flags';
const FEATURE_FLAGS_AVAILABLE = {
  [`${FEATURE_FLAG_PREFIX}at-risk`]: 'isAtRiskEnabled',
  [`${FEATURE_FLAG_PREFIX}delete-patient`]: 'isDeletePatientEnabled',
  [`${FEATURE_FLAG_PREFIX}patient-grouping`]: 'isPatientGroupingEnabled',
};

const FEATURE_FLAGS_LABELS: { [flag: string]: string } = {
  isAtRiskEnabled: 'At-Risk Score',
  isDeletePatientEnabled: 'Delete Patient',
  isPatientGroupingEnabled: 'Patient Grouping',
};

export function FeatureFlags() {
  const location = useLocation();
  const [featureFlags, setFeatureFlags] = useState<string[]>([]);

  const disableFlag = useCallback(
    (flag: string) => {
      const flags = getStoredFeatureFlags();

      flags[flag] = false;
      setFeatureFlags(getEnabledFlags(flags));
    },
    [setFeatureFlags]
  );

  useEffect(() => {
    const flags = getStoredFeatureFlags();

    setFeatureFlags(getEnabledFlags(flags));
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const flagsKeys = Array.from(query.keys()).filter((key) => key.startsWith(FEATURE_FLAG_PREFIX));

    if (flagsKeys.length === 0) {
      return;
    }

    const flags = getStoredFeatureFlags();

    flagsKeys.forEach((flagKey) => {
      if (query.has(flagKey)) {
        const flag = FEATURE_FLAGS_AVAILABLE[flagKey as keyof typeof FEATURE_FLAGS_AVAILABLE];

        if (!flag) {
          return;
        }

        flags[flag] = query.get(flagKey) !== 'false';
        query.delete(flagKey);
      }
    });

    saveFeatureFlags(flags);
    setFeatureFlags(getEnabledFlags(flags));

    if (window.history.pushState) {
      const queryStr = query.toString();
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }${queryStr ? `?${queryStr}` : ''}`;

      window.history.pushState({ path: newurl }, '', newurl);
    }
  }, [location.search]);

  return (
    <div className={styles.container}>
      {featureFlags.map((flag) => (
        <span className={styles.flagSpan} key={flag} onClick={() => disableFlag(flag)}>
          {FEATURE_FLAGS_LABELS[flag]}
        </span>
      ))}
    </div>
  );
}

type FeatureFlagsType = {
  isAtRiskEnabled: boolean;
  isDeletePatientEnabled: boolean;
  isPatientGroupingEnabled: boolean;
  [flag: string]: boolean;
};

function getEnabledFlags(flags: FeatureFlagsType): React.SetStateAction<string[]> {
  return Object.entries(flags)
    .filter(([, isEnabled]) => isEnabled)
    .map(([flag]) => flag.replace(FEATURE_FLAG_PREFIX, ''));
}

export function getStoredFeatureFlags(): FeatureFlagsType {
  let flags: FeatureFlagsType;

  try {
    flags = JSON.parse(sessionStorage.getItem(FEATURE_FLAGS_STORAGE_KEY) ?? '{}');
  } catch (e: unknown) {
    flags = {
      isAtRiskEnabled: false,
      isDeletePatientEnabled: false,
      isPatientGroupingEnabled: false,
    };
  }

  return flags;
}

function saveFeatureFlags(flags: FeatureFlagsType) {
  sessionStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, JSON.stringify(flags));
}
